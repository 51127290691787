const config = {
  siteTitle: 'Avocado Notes', // Site title.
  siteTitleShort: 'Project based learning for designers and developers.', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Project based learning for designers and developers.', // Alternative site title for SEO.
  siteLogo: '/logos/logo-1024.png', // Logo used for SEO and manifest.
  siteUrl: 'http://avocadonotes.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Notes + Code for developers and designers. Always learning new technology, and hoping to share helping others grow.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: '1825356251115265', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-161211056-1', // GA tracking ID.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  userName: 'Girish Raina Jammu', // Username to display in the author segment.
  userEmail: 'girishraina.jammu@gmail.com', // Email used for RSS feed's author segment
  userTwitter: 'girishjammu', // Optionally renders "Follow Me" in the Bio segment.
  userGitHub: 'girishjammu', // Optionally renders "Follow Me" in the Bio segment.
  userLocation: 'Raleigh-Durham, NC', // User location to display in the author segment.
  userAvatar: 'https://media-exp1.licdn.com/dms/image/C4D03AQHMGeoXY8VcDg/profile-displayphoto-shrink_200_200/0?e=1593043200&v=beta&t=Z1SspS-Q10SVoYXl062uBZMyj6vbuIq0t0kQXL5YoZY', // User avatar to display in the author segment.
  userDescription: "Y", // User description to display in the author segment.
  copyright: 'Copyright © 2020. All rights reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: 'red' // Used for setting manifest background color.
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
// if (config.siteRss && config.siteRss[0] !== "/")
//   config.siteRss = `/${config.siteRss}`;

module.exports = config
